import styled from '@emotion/styled'
// import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { Media } from '@hmn/rtl-web-core/components/Media'
import useNavigation from '@hmn/rtl-web-core/hooks/useNavigation'

import { ConditionalWrapper } from '../ConditionalWrapper'
import { BrakNPMenuItem } from '../ExtraNavItems/BrakNPMenuItem'
import { FreemailButton, freemailButtonVariants } from '../FreemailButton'
import { SearchBar, searchBarVariants } from '../Search'
import { Menu } from './components/Menu'
import { NavigationButton } from './components/NavigationButton'
import { Panel } from './components/Panel'
import styles from './Navigation.style'

// const UserGeneratedContent = dynamic(
//     () => import('../Cta/components/UserGeneratedContent/UserGeneratedContent.net.component'),
//     { ssr: false }
// )

const NavigationStyled = styled.div(props => ({ ...styles(props) }))

const gtmData = {
    eventCategory: 'Navigation',
    eventLabel: 'Navigation Item',
    eventValue: 'Menu'
}
function Navigation({ navigationData, variant, ...rest }) {
    const menuLinks = useNavigation(navigationData, 'main', {
        insert: [[6, BrakNPMenuItem]]
    })
    // console.log(menuLinks)
    return (
        <NavigationStyled className="intextAdIgnore" variant={variant} {...rest}>
            <ConditionalWrapper condition={variant === 'navigation'} Wrapper={Panel}>
                <div className="navigation">
                    {variant === 'navigation' && (
                        <>
                            <Media lessThan="md" scaleDown className="navigation_header">
                                <SearchBar
                                    className="navigation_search"
                                    variant={searchBarVariants.NAVIGATION}
                                    autoFocus
                                />
                                <NavigationButton className="navigation_button" />
                            </Media>
                            <Media lessThan="md" scaleDown className="navigation_footer">
                                <FreemailButton variant={freemailButtonVariants.MENU} />
                            </Media>
                        </>
                    )}
                    <div className="navigation_content">
                        <Menu className="navigation_menu" links={menuLinks} variant={variant} gtmData={gtmData} />

                        {/* <UserGeneratedContent className="navigation_ugc" hasTitleLineBreak variant="gamma" /> */}
                    </div>

                    <Media lessThan="md" scaleDown className="navigation_content_overlay" />
                </div>
            </ConditionalWrapper>
        </NavigationStyled>
    )
}

const navigationVariants = Object.freeze({
    NAVIGATION: 'navigation',
    FOOTER: 'footer'
})

Navigation.propTypes = {
    variant: PropTypes.oneOf([...Object.values(navigationVariants)]),
    navigationData: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.object)]).isRequired
}

Navigation.defaultProps = {
    variant: navigationVariants.NAVIGATION
}

export { navigationVariants }
export default withErrorBoundary(Navigation, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Navigation]: ', error, componentStack)
    }
})
